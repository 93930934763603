import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy and Use of Cookies" />
    <article>
      <div className="c-content">
        <div className="container">
          <div className="row">
            <div className="c-content__content-container">
              <div className="row">
                <div className="c-content__title-container">
                  <h2 className="c-content__title">Privacy and Use of Cookies</h2>
                </div>
                <div className="c-content__summary-container cms-content">
                  <p>Noah Media Group is the “controller” for the purposes of data protection law. This means that we are responsible for deciding how we hold and use personal data about you.</p>
                  <p>This Privacy Notice applies from 25 May 2018, when the General Data Protection Regulation comes into force. We may update this Privacy Notice at any time.</p>
                  <p>We are required by data protection law to give you the information in this Privacy Notice and it is important that you read it carefully.</p>
                  <p>
                    Information we collect, and how we collect it:<br/>
                    Noah Media Group does not collect any personally identifiable information from visitors to this website.
                  </p>
                  <p>Noah Media Group does use cookies, as part of the Google Analytics software running on the site.</p>
                  <p>
                    How we use information:<br/>
                    We use information from cookies to help us understand how visitors use our website.
                    Data collected helps us update the website so that it remains current, easy to navigate and that any issues with the site are quickly resolved.
                  </p>
                  <p>To find out more about how Google uses the information that is collected please see ‘How Google uses information from sites or apps that use our services’.</p>
                  <p>
                    Links:<br/>
                    The Noah Media Group (The Edge) website may contain links to other websites.
                    Please note that when you click on one of these links, you are entering another site.
                    We have no responsibility for the content of other sites, and encourage you to read the privacy policies of these linked sites, which may differ from ours.
                  </p>
                  <p>
                    Security:<br/>
                    We use technological and organisational measures to protect personal data (and other information) from unauthorized disclosure, alteration or destruction.
                  </p>
                  <p>
                    Changes to this Policy:<br/>
                    From time to time, we may revise this Policy to reflect changes in our business or the law.
                    If we make any material change to this Policy and we hold your address, we will notify you.
                  </p>
                  <p>
                    Amazon:<br/>
                    Noah Media Group is a participant in the Amazon Services LLC Associates Program,
                    an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to amazon.com
                  </p>
                  <h2>What are cookies?</h2>
                  <p>
                    A cookie is a small text file that a website saves on your computer or mobile device when you visit the site.
                    It enables the website to remember your actions and preferences (such as login, language, font size, and other display preferences) over a period of time,
                    so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.
                    Cookies do not give access to your personally-identifiable information.
                    The vast majority of websites now use cookies and some are essential for a website to function.
                  </p>
                  <h3>What cookies do we use?</h3>
                  <p>First party cookies: First party cookies are set by the website you are visiting and they can only be read by that site.</p>
                  <p>
                    Third party cookies: Third party cookies are set by a different organisation to that of the website you are visiting.
                    For example, the website might use a third party analytics company e.g. Google, who will set their own cookie to perform this service.
                    The website you are visiting may also contain content embedded from other sites for example YouTube, Flickr or Facebook, which set their own cookies.
                  </p>
                  <p>
                    We use Google Analytics to analyse the use of this website.
                    Google Analytics generates statistical and other information about website use by means of cookies, which are stored on users' computers.
                    The information generated relating to our website is used to create reports about the use and performance of the website.
                    Google will store this information. Google's privacy policy is available at www.google.com/privacypolicy.html
                  </p>
                  <p>
                    We do not share, rent or sell any data collected from cookies with any third-party.
                    They are solely used to improve your experience of using www.theedgefilm.com and to allow us to evaluate and improve our site.
                  </p>
                  <p>The following is a list of all cookies on our site and their purpose:</p>
                  <h3>Essential Cookies</h3>
                  <p>
                    Name: NoahMediaGroupCookiePolicy<br/>
                    Purpose: Stores the visitor's cookie consent<br/>
                    Expiry: 1 year
                  </p>
                  <p>
                    Name: _ga<br/>
                    Purpose: Registers a unique ID that is used to generate statistical data on how the visitor uses the website.<br/>
                    Expiry: 2 years
                  </p>
                  <p>
                    Name: _gat<br/>
                    Purpose: Used by Google Analytics to throttle request rate<br/>
                    Expiry: Sessions
                  </p>
                  <p>
                    Name: _gid<br/>
                    Purpose: Registers a unique ID that is used to generate statistical data on how the visitor uses the website.<br/>
                    Expiry: Session
                  </p>
                  <p>
                    Name: Collect (Google Analytics)<br/>
                    Purpose: Used to send data to Google Analytics about the visitor's device and behaviour. Tracks the visitor across devices and marketing channels.<br/>
                    Expiry: Session
                  </p>
                  <h3>How long will cookies stay on my device?</h3>
                  <p>Session cookies: Session Cookies are stored temporarily during a browsing session and are deleted from the user’s device when the browser is closed.</p>
                  <p>
                    Persistent cookies: This type of cookie is saved on your computer for a fixed period (usually a year or longer) and is not deleted when the browser is closed.
                    Persistent cookies are used where we need to know who you are for more than one browsing session.
                    For example, this type of cookie is used to store your preferences or details e.g. your email address that you may have entered it in a form,
                    so that they are remembered for your next visit.
                  </p>
                  <h3>How to control and delete cookies</h3>
                  <p>
                    You should be aware that any preferences will be lost if you delete cookies and many websites will not work properly or you will lose some functionality.
                    The method for rejecting cookies and removing existing cookies varies depending on which browser you use:
                  </p>
                  <p>
                    <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer</a><br/>
                    <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Firefox</a><br/>
                    <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Google Chrome</a><br/>
                    <a href="https://help.apple.com/safari/mac/8.0/#/sfri11471" target="_blank">Safari</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export default PrivacyPolicy
